import { HTTP } from '../../axios/axios'
let data

async function getAll (lang, page) {
  try {
    const res = await HTTP.get('/zigzag/pages/' + page, { headers: { 'accept-language': lang } })
    data = res.data
    return res.data
  } catch (error) {
    console.log(error)
  }
}
async function addOne (zigzag, lang) {
  return await HTTP.patch('/zigzag/single-add', zigzag, { params: { page: data.page }, headers: { 'accept-language': lang } })
}
async function update (zigzag, id) {
  return await HTTP.patch('/zigzag/single-update', zigzag, { params: { page: data.page, zigzag: id }, headers: { 'accept-language': data.lang } })
}
async function deleteOne (zigzag) {
  const zigzags = data.zigzags.filter(i => i._id !== zigzag._id)
  return await HTTP.patch('/zigzag/' + data._id, { zigzags })
}
async function deleteMany (ids) {
  const zigzags = data.zigzags.filter(i => ids.indexOf(i._id) === -1)
  return await HTTP.patch('/zigzag/' + data._id, { zigzags })
}
async function changeOrder (zigzags, oldId) {
  return await HTTP.patch('/zigzag/' + data._id, { zigzags })
}

export default { getAll, deleteMany, addOne, update, deleteOne, changeOrder }
